// import axios from "axios";
let config = {}; // Initialize as an empty object

config['BASE_URL'] = 'http://candidate-hrms.dtsmis.in/';
config['API_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/candidate/';
config['GLOB_API_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/global/';
config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
config['IMAGE_PATH'] = 'https://api-hrms.dtsmis.in:3008/public/uploads/';


// config['BASE_URL'] = 'http://jobs.hlfppt.org/';
// config['API_URL'] = 'https://hrapi.hlfppt.org/v1/candidate/';
// config['GLOB_API_URL'] = 'https://hrapi.hlfppt.org/v1/global/';
// config['IMAGE_PATH'] = 'https://hrapi.hlfppt.org/public/uploads/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
// config['FRONT_URL'] = 'https://jobs.hlfppt.org/';

// config['BASE_URL'] = 'https://hrapi.hlfppt.org/v1';
// config['API_URL'] = 'https://hrapi.hlfppt.org/v1/candidate/';
// config['IMAGE_PATH'] = 'https://hrapi.hlfppt.org/public/uploads/';
// config['FRONT_URL'] = 'https://jobs.hlfppt.org/';
// config['GLOB_API_URL'] = 'https://api-hrms.dtsmis.in:3008/v1/global/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';




// config['BASE_URL'] = 'https://apis.duplextech.com:3018/v1';
// config['API_URL'] = ' https://apis.duplextech.com:3018/v1/candidate/';
// config['GLOB_API_URL'] = 'https://apis.duplextech.com:3018/v1/global/';
// config['IMAGE_PATH'] = 'https://apis.duplextech.com:3018/public/uploads/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
// config['FRONT_URL'] = 'https://career.duplextech.com/';

// config['LOGO_PATH'] = 'https://res.cloudinary.com/duplex-tech/image/upload/v1689317653/newDuplex/setting/eviirl8exl9agdov3ej6.png'

// https://career.duplextech.com





config['SESSIONKEY'] = 'loginData';

export default config;



// config['LOGO_PATH'] = logo;

// config['COMPANY_NAME'] = 'HRMS WEB APP';
// config['PANEL_NAME'] = 'HRMS Login';
// config['HELP_URL'] = 'support';
// config['PRIVACY_URL'] = 'pivacy-policy';
// config['TERMS_URL'] = 'terms-conditions';
